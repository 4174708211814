// react-router-dom components

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";

// Data
import { useSelector } from "react-redux";
import Card from "@mui/material/Card";
import { styled } from "@mui/material/styles";
import logoMate from "assets/images/pgw/Logo-Respect-Zone-Flat.webp";
import { Link } from "react-router-dom";

const StyledImage = styled("img")({
  width: "100%",
  height: "auto",
});

function RespectMate() {
  const link = useSelector((state) => state.referentiel.link);
  return link === null ? null : (
    <MKBox component="section" py={6}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 0, mx: "auto" }}
        >
          <MKBadge variant="contained" container sx={{ mb: 2 }} />
          <MKTypography variant="h2" fontWeight="bold">
            Respecte ton mate
          </MKTypography>
          <br />
        </Grid>
        <Grid container spacing={4} justifyContent="center" alignItems={"center"}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Link to="/Charte-RZ.pdf" target="_blank">
              <Card
                sx={{
                  height: "300px",
                  padding: 1,
                  alignItems: "center",
                  justifyContent: "center",
                  bgcolor: "rgba(0, 0, 0, 0.1)",
                  "&:hover": {
                    background: "rgba(0, 0, 0, 0.2)",
                    outline: "outset #DB2364",
                  },
                }}
              >
                <StyledImage src={logoMate} alt={"Respect Zone"} loading="lazy" />
              </Card>
            </Link>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default RespectMate;
