// react-router-dom components

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";

// Data
import planningPgw from "../../../assets/images/pgw/Planning.webp";
import rules from "../../../assets/images/pgw/rules-icon.webp";
import activities from "../../../assets/images/pgw/activities.png";
import { useSelector } from "react-redux";
import MKButton from "../../../components/MKButton";
// import Stack from "@mui/material/Stack";
// import Divider from "@mui/material/Divider";
import { Link } from "react-router-dom";

const TwitchIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48" height="48">
    <path
      fill="#000000"
      d="M11.64 5.93h1.43v4.28h-1.43m3.93-4.28H17v4.28h-1.43M7 2L3.43 5.57v12.86h4.28V22l3.58-3.57h2.85L20.57 12V2m-1.43 9.29l-2.85 2.85h-2.86l-2.5 2.5v-2.5H7.71V3.43h11.43Z"
    />
  </svg>
);

const ShopIcon = () => (
  <svg
    fill="#000000"
    height="48"
    width="48"
    version="1.1"
    id="XMLID_269_"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
  >
    <g id="shop-cart">
      <g>
        <circle cx="9" cy="21" r="2" fill="#000000" />
      </g>
      <g>
        <circle cx="19" cy="21" r="2" fill="#000000" />
      </g>
      <g>
        <path
          d="M21,18H7.2l-4-16H0V0h4.8l0.8,3H24l-3.2,11H8.3l0.5,2H21V18z M7.8,12h11.5l2-7H6L7.8,12z"
          fill="#000000"
        />
      </g>
    </g>
  </svg>
);

function Pgw() {
  const link = useSelector((state) => state.referentiel.link);
  return link === null ? null : (
    <MKBox component="section" py={6}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 2, mx: "auto" }}
        >
          <MKBadge variant="contained" container sx={{ mb: 2 }} />
          <MKTypography variant="h2" fontWeight="bold">
            Paris Games Week 2024
          </MKTypography>
          <br />
          <MKTypography variant="body1" color="text">
            <Grid container spacing={2} gap={0}>
              <Grid item xs={6}>
                <MKButton
                  component={Link}
                  to={"/pages/reglement-pgw"}
                  variant={"gradient"}
                  color={"primary"}
                  size="small"
                >
                  <img src={rules} width={"48px"} height={"48px"} />
                  Règlement
                  <br />
                  PGW
                  <img src={rules} width={"48px"} height={"48px"} />
                </MKButton>
              </Grid>
              <Grid item xs={6}>
                <MKButton
                  component="a"
                  href={`${link.find((e) => e.libelle === "linkRzoTwitch").value}`}
                  target="_blank"
                  rel="noreferrer"
                  variant={"gradient"}
                  color={"primary"}
                  size="small"
                >
                  <TwitchIcon />
                  Lien du stream
                  <br />
                  Cliquer ici !
                  <TwitchIcon />
                </MKButton>
              </Grid>
              <Grid item xs={6}>
                <MKButton
                  component="a"
                  href="https://boutique.gamegend.fr"
                  target="_blank"
                  rel="noreferrer"
                  variant={"gradient"}
                  color={"primary"}
                  size="small"
                >
                  <ShopIcon />
                  Boutique
                  <br />
                  GameGend
                  <ShopIcon />
                </MKButton>
              </Grid>
              <Grid item xs={6}>
                <MKButton
                  component={Link}
                  to={"/pages/activites-pgw"}
                  variant={"gradient"}
                  color={"primary"}
                  size="small"
                >
                  <img src={activities} width={"48px"} height={"48px"} />
                  Nos Activités
                  <img src={activities} width={"48px"} height={"48px"} />
                </MKButton>
              </Grid>
            </Grid>
          </MKTypography>
        </Grid>
        <Grid
          container
          spacing={0}
          justifyContent="center"
          alignItems="center"
          sx={{ textAlign: "center" }}
        >
          <Grid item>Planning Twitch PGW</Grid>
          <Grid item>
            <MKBox component="img" src={planningPgw} alt="PGW" width="80%" opacity={1} />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Pgw;
