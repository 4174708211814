import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBadge from "../../../components/MKBadge";
import MKTypography from "../../../components/MKTypography";
import MKBox from "../../../components/MKBox";

const Breve = () => {
  return (
    <MKBox component="section" py={6}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 0, mx: "auto" }}
        >
          <MKBadge variant="contained" container sx={{ mb: 2 }} />
          <MKTypography variant="h2" fontWeight="bold">
            Informations
          </MKTypography>
          <br />
        </Grid>
        <Grid
          container
          item
          xs={2}
          lg={30}
          flexDirection="column"
          sx={{ textAlign: "left", my: 0, mx: "auto", px: 0 }}
        >
          <MKTypography variant="body1" color="text">
            Les achats de goodies en édition limitée effectués pendant la Paris Games Week sont en
            cours de traitement. Comme indiqué dans les conditions générales de ventes, leur
            traitement et leur expédition seront effectués courant novembre. Vous recevrez un mail
            de confirmation avec un numéro de suivi une fois que la commande sera traitée. Merci de
            votre patience.
          </MKTypography>
        </Grid>
      </Container>
    </MKBox>
  );
};

export default Breve;
