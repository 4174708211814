// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "../../components/MKBox";
// import MKInput from "../../components/MKInput";
// import MKButton from "../../components/MKButton";
import MKTypography from "../../components/MKTypography";

// Image
import bgImage from "../../assets/images/gamegend/GIF_2.4_Light.webp";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";

function ContactUs() {
  const link = useSelector((state) => state.referentiel.link);
  return link === null ? null : (
    <>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} lg={6}>
          <MKBox
            display={{ xs: "none", lg: "flex" }}
            width="calc(100% - 2rem)"
            height="calc(100vh - 2rem)"
            borderRadius="lg"
            ml={2}
            mt={2}
            sx={{
              backgroundImage: `url(${bgImage})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={7}
          lg={6}
          xl={4}
          ml={{ xs: "auto", lg: 6 }}
          mr={{ xs: "auto", lg: 6 }}
        >
          <MKBox
            bgColor="white"
            borderRadius="xl"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mt={{ xs: 20, sm: 18, md: 20 }}
            mb={{ xs: 20, sm: 18, md: 20 }}
            mx={3}
          >
            <MKBox
              variant="gradient"
              bgColor="info"
              coloredShadow="info"
              borderRadius="lg"
              p={2}
              mx={2}
              mt={-3}
            >
              <MKTypography variant="h3" color="white">
                Contactez nous
              </MKTypography>
            </MKBox>
            <MKBox p={3}>
              <MKTypography variant="body2" color="text" mb={3}>
                Pour toutes questions, partenariat ou autres demandes, merci de nous contacter via
                l&apos;adresse mail {link.find((l) => l.libelle === "emailContact").value}
                &nbsp;ou en cliquant sur ce bouton.
              </MKTypography>
              <MKBox width="100%" component="form" method="post" autoComplete="off">
                <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    target="_top"
                    rel="noopener noreferrer"
                    href={`mailto:${link.find((l) => l.libelle === "emailContact").value}`}
                  >
                    <Typography variant="button" style={{ color: "white", fontSize: "0.69rem" }}>
                      Nous contacter
                    </Typography>
                  </Button>
                </Grid>
              </MKBox>
            </MKBox>
          </MKBox>
        </Grid>
      </Grid>
      <MKBox pt={6} px={1} mt={6}></MKBox>
    </>
  );
}

export default ContactUs;
